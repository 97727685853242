import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getAccountsData } from "selectors/accounts";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import DataConstants from "const/DataConstants";
import DateRangePickerExtended from "nlib/common/DateRangePickerExtended";
import DebounceInput from "nlib/ui/DebounceInput";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Row from "nlib/ui/Row";
import Select from "nlib/ui/Select";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const Filters = ({ compact, disabled, className }) => {
  const [envVars, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const accountsData = useSelector(getAccountsData);

  const {
    text = "",
    fromDate,
    toDate,
    accountId,
    type,
    month
  } = envVars;

  const [textInputValue, setTextInputValue] = useState(text);

  const fromToDate = useMemo(() => [fromDate, toDate], [fromDate, toDate]);

  const filtersActive = [text, fromDate, toDate, accountId, type].some(Boolean);

  const showResetButton = [filtersActive, month].some(Boolean);

  const accountOptions = useMemo(() => [
    accountId && { label: uiTexts.all },
    ...accountsData
      .filter(({ extraData: { paymentAccount } = {} }) => !quickBooksBusiness || paymentAccount)
      .map(({ id, name, accountNumber }) => ({
        value: id,
        label: name || accountNumber
      }))
  ], [accountId, accountsData, quickBooksBusiness, uiTexts.all]);

  const typeOptions = useMemo(() => [
    type && { label: uiTexts.all },
    { value: DataConstants.TRANSACTION_TYPES.WITHDRAW, label: uiTexts.expenses },
    { value: DataConstants.TRANSACTION_TYPES.DEPOSIT, label: uiTexts.income }
  ], [type, uiTexts]);

  const handleTextInputComplete = useCallback((value) => {
    setEnvVars({ text: value || null, month: null });
  }, [setEnvVars]);

  const handleDateChange = useCallback((value) => {
    const [from, to] = value;

    setEnvVars({ fromDate: from, toDate: to, month: null });
  }, [setEnvVars]);

  const handleAccountChange = useCallback((value) => {
    setEnvVars({ accountId: value, month: null });
  }, [setEnvVars]);

  const handleTypeChange = useCallback((value) => {
    setEnvVars({ type: value, month: null });
  }, [setEnvVars]);

  const handleResetButtonClick = useCallback(() => {
    setEnvVars({ text: null, fromDate: null, toDate: null, accountId: null, type: null, month: null });
  }, [setEnvVars]);

  useEffect(() => {
    setTextInputValue(text);
  }, [text]);

  return (
    <div className={classNames(Css.filters, className, filtersActive && Css.active)} disabled={disabled}>
      <Row>
        <DebounceInput
          cleanable
          active={!!text}
          className={Css.search}
          placeholder={uiTexts.searchTransactions}
          value={textInputValue}
          iconBefore={Icons.MagnifyingGlass}
          onChange={setTextInputValue}
          onInputComplete={handleTextInputComplete} />

        {!compact && (
          <>
            <DateRangePickerExtended
              range
              active={!!fromDate && !!toDate}
              value={fromToDate}
              className={Css.date}
              placeholder={uiTexts.selectDate}
              onChange={handleDateChange} />

            <Select
              active={!!accountId}
              className={Css.account}
              placeholder={uiTexts.selectAccount}
              iconBefore={Icons.Bank}
              value={accountId}
              options={accountOptions}
              onChange={handleAccountChange} />

            <Select
              active={!!type}
              className={Css.type}
              placeholder={uiTexts.selectType}
              iconBefore={Icons.CurrencyCircleDollar}
              value={type}
              options={typeOptions}
              onChange={handleTypeChange} />

            <Button
              large outline danger
              className={classNames(Css.resetButton, showResetButton && Css.active)}
              icon={Icons.X}
              onClick={handleResetButtonClick}>
              {uiTexts.resetFilters}
            </Button>
          </>
        )}
      </Row>
    </div>
  );
};

export default React.memo(Filters);
