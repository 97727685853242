import React, { useContext, useMemo } from "react";
import TransactionDetails from "./lib/TransactionDetails";
import TransactionsContext from "contexts/TransactionsContext";
import Utils from "utils/Utils";

const Transaction = ({ transaction, onClose }) => {
  const { id: transactionId } = transaction;

  const {
    localReasons,
    transactionsState
  } = useContext(TransactionsContext);

  const transactionState = useMemo(() => {
    return Utils.arrayFindById(transactionsState, transactionId, {});
  }, [transactionId, transactionsState]);

  return (
    <TransactionDetails
      key={transactionId}
      localReasons={localReasons}
      transaction={transaction}
      transactionState={transactionState}
      onClose={onClose} />
  );
};

export default React.memo(Transaction);
