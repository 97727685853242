import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React from "react";
import TransactionAmount from "nlib/common/TransactionAmount";
import classNames from "classnames";
import moment from "moment";

const TransactionInfo = ({ className, documentPreviewMode, transaction }) => {
  const {
    type,
    timestamp,
    amount,
    currency,
    account,
    description
  } = transaction;

  const { uiTexts } = useSelector(getTextsData);

  return (
    <div className={classNames(Css.transactionInfo, documentPreviewMode && Css.documentPreviewMode, className)}>
      <div className={Css.infoWrapper}>
        <div className={Css.mainInfo}>
          {!!timestamp && (
            <div className={Css.timestamp}>
              <Icons.CalendarBlank />
              <span>
                {moment.utc(timestamp).format(Constants.DATETIME_FORMATS.DATE_TEXT)}
              </span>
            </div>
          )}
          <TransactionAmount
            className={Css.amount}
            amount={amount}
            type={type}
            currency={currency} />
        </div>
        <div className={Css.additionalInfo}>
          <Icons.CreditCard />
          <span>{account.name}</span>
        </div>
      </div>
      <div className={classNames(Css.description, !description && Css.warning)}>
        {description
          ? (<Icons.Info />)
          : (<Icons.Warning />)}
        <span>{description || `${uiTexts.noBankDescriptionOrMemo} :(`}</span>
      </div>
    </div>
  );
};

export default React.memo(TransactionInfo);
