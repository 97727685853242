import Css from "./style.module.scss";

import { useDispatch } from "react-redux";
import Constants from "const/Constants";
import MainApiActions from "actions/MainApiActions";
import Preloader from "nlib/common/Preloader";
import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import useWindowResize from "hooks/useWindowResize";

const Viewer = React.lazy(() => import("./dll"));

const DEFAULT_ZOOM_LEVEL = "PageWidth";

const PdfViewer = ({ attachment, className }) => {
  const dispatch = useDispatch();

  const [pdfUrl, setPdfUrl] = useState(null);

  const [windowResized, setWindowResized] = useState(false);

  const fetchData = useCallback(async() => {
    if (attachment?.key) {
      const result = await dispatch(MainApiActions.fetchAttachmentUrl(attachment));

      setPdfUrl(result);
    }
  }, [attachment, dispatch]);

  const handleWindowResize = useMemo(() => {
    return Utils.debounce(() => {
      setWindowResized(true);
    }, Constants.WINDOW_RESIZE_DEBOUNCE_DELAY);
  }, []);

  const renderViewer = useCallback((props) => {
    if (windowResized) {
      Utils.deferredRun(() => {
        setWindowResized(false);
        props.zoom(DEFAULT_ZOOM_LEVEL);
      });
    }

    return props.viewer;
  }, [windowResized]);

  useWindowResize(handleWindowResize);

  useEffect(() => {
    if (!pdfUrl) fetchData();
  }, [pdfUrl, fetchData]);

  return (
    <div className={classNames(Css.pdfViewer, className)}>
      {attachment?.key
        ? (
          pdfUrl
            ? (
              <Suspense fallback={<Preloader />}>
                <Viewer
                  fileUrl={pdfUrl}
                  defaultScale={DEFAULT_ZOOM_LEVEL}
                  render={renderViewer} />
              </Suspense>
            )
            : <Preloader />
        )
        : <Preloader />}
    </div>
  );
};

export default React.memo(PdfViewer);
