import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkDocumentsFetching } from "selectors/documents";
import { checkIntegrationsFetching } from "selectors/integrations";
import { checkMatchesFetching, getSelectedBusinessData } from "selectors/businesses";
import { checkTransactionsFetching } from "selectors/transactions";
import { checkUsersFetching } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import IntegrationsActions from "actions/IntegrationsActions";
import React, { useCallback } from "react";
import Utils from "utils/Utils";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";

const { QUICK_BOOKS, QUICK_BOOKS_DESKTOP, XERO, ZOHO } = IntegrationServices;

const HeaderAccountantContent = () => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const transactionsFetching = useSelector(checkTransactionsFetching);

  const usersFetching = useSelector(checkUsersFetching);

  const documentsFetching = useSelector(checkDocumentsFetching);

  const matchesFetching = useSelector(checkMatchesFetching);

  const fetchingIntegrations = useSelector(checkIntegrationsFetching);

  const showCommonModal = useShowCommonModal();

  const {
    id: businessId,
    createdAt: businessCreatedAt,
    extraData: {
      integrationService,
      integrationServiceConnected,
      transactionsLastSyncedAt,
      xeroBusinessHasTooMuchData
    } = {}
  } = useSelector(getSelectedBusinessData);

  const fetchingData = transactionsFetching || usersFetching || documentsFetching || matchesFetching;

  const xeroService = integrationService === XERO.value;

  const quickBooksService = integrationService === QUICK_BOOKS.value;

  const quickBooksDesktopService = integrationService === QUICK_BOOKS_DESKTOP.value;

  const zohoService = integrationService === ZOHO.value;

  const serviceName = Utils.capitalizeText(integrationService);

  const tooMuchData = !!xeroBusinessHasTooMuchData;

  const firstFullSync = integrationServiceConnected && !transactionsLastSyncedAt
    && moment(businessCreatedAt).isAfter(moment().subtract(1, "days"));

  const handleSyncIntegrationButtonClick = useCallback(async() => {
    const [
      integrationSyncConfirmMessageA,
      integrationSyncConfirmMessageB
    ] = messages.integrationSyncConfirm;

    const businessIds = [businessId];

    const modalResult = await showCommonModal({
      confirm: true,
      text: [
        `${Utils.replaceTextVars(integrationSyncConfirmMessageA, { serviceName })}`,
        integrationSyncConfirmMessageB,
        transactionsLastSyncedAt
          ? Utils.replaceTextVars(
            uiTexts.lastSyncDate,
            { date: moment.duration(moment.utc(transactionsLastSyncedAt).diff(moment.utc())).humanize(true) }
          )
          : null
      ].filter(Boolean).join("\n\n"),
      okButtonText: Utils.replaceTextVars(
        uiTexts.syncDataFromDate,
        { date: moment().subtract(1, "months").startOf("month").format(Constants.DATETIME_FORMATS.DATE_TEXT) }
      )
    });

    if (modalResult) {
      await dispatch(IntegrationsActions.syncAccounts(integrationService, businessIds, true, true));
      await dispatch(IntegrationsActions.syncClasses(integrationService, businessIds, true, true));
      if (xeroService || zohoService) {
        await dispatch(IntegrationsActions.syncTaxRates(integrationService, businessIds, true, true));
      }
      if (quickBooksService) {
        await dispatch(IntegrationsActions.syncLocations(integrationService, businessIds, true, true));
      }
      await dispatch(IntegrationsActions.syncContacts(integrationService, businessIds, true, true));
      if (zohoService) await dispatch(IntegrationsActions.syncProjects(integrationService, businessIds, true, true));
      await dispatch(IntegrationsActions.syncTransactions(integrationService, businessIds, true, false));
      dispatch(BusinessesActions.fetchBusiness(businessId, true));
    }
  }, [
    serviceName,
    businessId,
    dispatch,
    integrationService,
    messages,
    quickBooksService,
    transactionsLastSyncedAt,
    uiTexts,
    showCommonModal,
    xeroService,
    zohoService
  ]);

  return (
    <div className={Css.headerAccountantContent}>
      {integrationService && !quickBooksDesktopService && (
        firstFullSync
          ? (
            <div>
              <span>
                <b>
                  {Utils.replaceTextVars(
                    uiTexts.syncingTransactionsFromDate,
                    {
                      date: moment()
                        .subtract(1, "years")
                        .startOf(tooMuchData ? "day" : "year")
                        .format(Constants.DATETIME_FORMATS.DATE_TEXT)
                    }
                  )}
                </b>
              </span>
              <Icons.Spinner data-wait className={CommonCss.highlightText} />
            </div>
          )
          : (
            <Button
              light large
              disabled={fetchingData || fetchingIntegrations || !integrationServiceConnected || !transactionsLastSyncedAt}
              onClick={handleSyncIntegrationButtonClick}>
              <span>
                {fetchingIntegrations || !transactionsLastSyncedAt ? <Icons.Spinner data-wait /> : <Icons.ArrowsClockwise />}
                <span>{`${uiTexts.syncWith} ${Utils.capitalizeText(integrationService)}`}</span>
              </span>
            </Button>
          )
      )}
    </div>
  );
};

export default React.memo(HeaderAccountantContent);
