import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React from "react";

const { ROBOTIC_AI_STATUSES } = DataConstants;

const ICONS = {
  [ROBOTIC_AI_STATUSES.AWAITING_CATEGORIZATION]: Icons.Question,
  [ROBOTIC_AI_STATUSES.AWAITING_CLARIFICATION]: Icons.WarningCircle,
  [ROBOTIC_AI_STATUSES.AWAITING_REVIEW]: Icons.Eye,
  [ROBOTIC_AI_STATUSES.AWAITING_EXPORT]: Icons.UploadSimple,
  [ROBOTIC_AI_STATUSES.AWAITING_RECONCILIATION]: Icons.Clock
};

const ProgressCell = ({ roboticAiStatus = ROBOTIC_AI_STATUSES.UNKNOWN }) => {
  const { uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const [, progressStep] = roboticAiStatus.split(":");

  const Icon = ICONS[roboticAiStatus];

  const label = quickBooksBusiness && roboticAiStatus === ROBOTIC_AI_STATUSES.AWAITING_RECONCILIATION
    ? uiTexts.awaitingMatching
    : uiTexts[progressStep] || uiTexts.unknown;

  return (
    <div className={Css.progressCell}>
      <div className={Css.progressStatus} title={label} data-step={progressStep}>
        {Icon && <Icon />}
        <span className={Css.label}>{label}</span>
        <span>
          <Icons.CircleNotch data-wait />
        </span>
      </div>
    </div>
  );
};

export default React.memo(ProgressCell);
