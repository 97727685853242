import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import { checkTransactionsFetching, getLockedTransactions, getTransactionsStats } from "selectors/transactions";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AmountCell from "nlib/common/TransactionsTable/lib/AmountCell";
import Checkbox from "nlib/ui/Checkbox";
import Constants from "const/Constants";
import ExtraCell from "./lib/ExtraCell";
import Pagination from "nlib/ui/Pagination";
import React, { useCallback, useContext, useEffect } from "react";
import SelectPageSize from "nlib/ui/SelectPageSize";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import TransactionCell from "nlib/common/TransactionsTable/lib/TransactionCell";
import TransactionsContext from "contexts/TransactionsContext";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const BULK_VISIBLE_MIN_WIDTH = 900;

const TransactionsTableBusiness = (props) => {
  const {
    disabled,
    localReasons,
    allItemsSelected,
    editableTransactionsIds,
    transactionsReadyToReview,
    availableWidth,
    simplifyLayout,
    selectedTransactions,
    onSelectedChange,
    onSelectAllChange
  } = props;

  const {
    transactionsData,
    transactionsState: tableItemsState,
    setTransactionsState: setTableItemsState,
    refetchTransactions,
    setLocalReasons
  } = useContext(TransactionsContext);

  const [envVars, setEnvVars] = useEnvVars();

  const { page = 1, pageSize = Constants.TABLE_PAGE_SIZE } = envVars;

  const { uiTexts } = useSelector(getTextsData);

  const transactionsStats = useSelector(getTransactionsStats);

  const transactionsFetching = useSelector(checkTransactionsFetching);

  const lockedTransactions = useSelector(getLockedTransactions);

  const showBulkActions = availableWidth > BULK_VISIBLE_MIN_WIDTH;

  const handleTransactionChange = useCallback((id, update) => {
    setTableItemsState((prevState) => {
      return Utils.arrayUpdateItemById(prevState, id, (state) => {
        return { ...state, ...update };
      });
    });
  }, [setTableItemsState]);

  const handleReasonBlur = useCallback(() => {
    const reasons = tableItemsState.map((item) => item.reason).filter(Boolean);

    setLocalReasons(() => [...new Set(reasons)]);
  }, [tableItemsState, setLocalReasons]);

  const handleSelectedChange = useCallback((value, event) => {
    onSelectedChange(event.target.dataset.id, value);
  }, [onSelectedChange]);

  const handleTableSortChange = useCallback((sortParams) => {
    setEnvVars(sortParams);
  }, [setEnvVars]);

  const handlePageChange = useCallback((nextPage) => {
    setEnvVars({ page: nextPage });
  }, [setEnvVars]);

  const handlePageSizeChange = useCallback((nextPageSize) => {
    setEnvVars({ pageSize: nextPageSize });
  }, [setEnvVars]);

  useEffect(() => {
    if (transactionsFetching || transactionsData.length || !transactionsStats.current) {
      return;
    }

    const lastPage = Math.ceil(transactionsStats.current / pageSize);

    setEnvVars({ page: (+page > lastPage) ? lastPage : page });
  }, [
    page,
    pageSize,
    transactionsFetching,
    transactionsData.length,
    transactionsStats,
    setEnvVars
  ]);

  return (
    <div className={Css.tableContainer}>
      {!!transactionsData.length && (
        <Table
          disabled={disabled}
          className={Css.transactionsTable}
          sortBy={envVars.sortBy}
          sortOrder={envVars.sortOrder}
          onSortChange={handleTableSortChange}>
          <TableRow>
            <TableHead className={Css.checkboxCell} show={showBulkActions}>
              {!!transactionsData.length && (
                <Checkbox
                  disabled={!editableTransactionsIds.length}
                  checked={allItemsSelected}
                  indeterminate={!!selectedTransactions.length}
                  onChange={onSelectAllChange} />
              )}
            </TableHead>
            <TableHead className={Css.transactionCell} accessor="transaction">
              {uiTexts.transaction}
            </TableHead>
            <TableHead className={Css.amountCell} accessor="amount">
              {uiTexts.amount}
            </TableHead>
            <TableHead className={Css.placeholderCell} />
            <TableHead className={Css.extraCell} />
          </TableRow>
          {transactionsData.map((transaction) => {
            const { id: transactionId } = transaction;

            const selected = selectedTransactions.includes(transactionId);

            const transactionLocked = !!lockedTransactions[transactionId];

            const transactionState = Utils.arrayFindById(tableItemsState, transactionId, {});

            const readyToReview = !!Utils.arrayFindById(transactionsReadyToReview, transactionId);

            return (
              <TableRow
                key={transactionId}
                disabled={disabled || transactionLocked}
                className={classNames(
                  CommonCss.tableRow,
                  readyToReview && CommonCss.highlightRow
                )}>
                {showBulkActions && (
                  <TableCell className={Css.checkboxCell}>
                    <Checkbox
                      checked={selected}
                      data-id={transactionId}
                      disabled={!editableTransactionsIds.includes(transactionId)}
                      onChange={handleSelectedChange} />
                  </TableCell>
                )}
                <TableCell className={Css.transactionCell}>
                  <TransactionCell transaction={transaction} />
                </TableCell>
                <TableCell className={Css.amountCell}>
                  <AmountCell transaction={transaction} />
                </TableCell>
                <TableCell className={Css.placeholderCell} />
                <TableCell className={Css.extraCell}>
                  <ExtraCell
                    disabled={disabled}
                    selected={selected}
                    readyToReview={readyToReview}
                    simplifyLayout={simplifyLayout}
                    localReasons={localReasons}
                    transaction={transaction}
                    transactionState={transactionState}
                    refetchTableData={refetchTransactions}
                    onChange={handleTransactionChange}ы
                    onSelectedChange={onSelectedChange}
                    onReasonBlur={handleReasonBlur} />
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      )}
      <div className={Css.footer}>
        {(transactionsStats.current > Number(pageSize)) && (
          <Pagination
            className={Css.pagination}
            count={transactionsStats.current}
            page={Number(page)}
            pageSize={Number(pageSize)}
            disabled={disabled}
            onChange={handlePageChange} />
        )}
        {(transactionsStats.current > Constants.TABLE_PAGE_SIZE) && (
          <SelectPageSize
            className={Css.pageSize}
            disabled={disabled}
            pageSize={Number(pageSize)}
            onChange={handlePageSizeChange} />
        )}
      </div>
    </div>
  );
};

export default React.memo(TransactionsTableBusiness);
