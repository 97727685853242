import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser, getUserData } from "selectors/user";
import {
  checkSelectedBusinessHasBusinessUsers,
  getGlobalStats,
  getSelectedBusinessData
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AskClientPopup from "nlib/common/AskClientPopup";
import BulkActions from "nlib/common/TransactionsTable/lib/BulkActions";
import DataConstants from "const/DataConstants";
import EmptyState from "./lib/EmptyState";
import Filters from "./lib/Filters";
import HeaderAccountantContent from "./lib/HeaderAccountantContent";
import MonthsTabs from "nlib/common/MonthsTabs";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sidebar from "./lib/Sidebar";
import TransactionsActions from "actions/TransactionsActions";
import TransactionsContext from "contexts/TransactionsContext";
import TransactionsIntroModal from "nlib/common/TransactionsIntroModal";
import TransactionsPopup from "nlib/common/TransactionsPopup";
import TransactionsStatusFilter from "nlib/common/TransactionsStatusFilter";
import TransactionsTable from "./lib/TransactionsTable";
import TransactionsTableBusiness from "./lib/TransactionsTableBusiness";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useAvailableWidth from "hooks/useAvailableWidth";
import useEnvVars from "hooks/useEnvVars";
import useTransactions from "hooks/useTransactions";

const { NEED_REACTION, TO_REVIEW, TO_REPORT, TO_RECONCILE } = DataConstants.STATUSES;

const SIMPLIFIED_LAYOUT_MAX_WIDTH = 900;

const TransactionsPage = () => {
  const dispatch = useDispatch();

  const [envVars, setEnvVars] = useEnvVars();

  const [editItemId] = envVars.editItem ? envVars.editItem.split(".") : [];

  const { uiTexts, messages } = useSelector(getTextsData);

  const { businessOrganization, whiteLabel } = useSelector(getActiveOrganization);

  const userData = useSelector(getUserData);

  const businessUser = useSelector(checkIsBusinessUser);

  const selectedBusinessHasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  const globalStats = useSelector(getGlobalStats);

  const { extraData: { transactionsLastSyncedAt, xeroBusinessHasTooMuchData } = {} } = useSelector(getSelectedBusinessData);

  const {
    fetching,
    popupMode,
    transactionsForPopup = [],
    selectedTransactions,
    selectedTransactionsData,
    editableTransactionsIds,
    transactionsReadyToProcess,
    transactionsReadyToReview,
    transactionsReadyToApprove,
    selectedNeedReactionIds,
    closedBookDateTransactionIds,
    transactionsFetching,
    transactionsData,
    transactionsState,
    setTransactionsState,
    refetchTransactions,
    setSelectedTransactions,
    onSelectedChange,
    onAskClientPopupClose,
    onBulkActionsEdit,
    onBulkActionsCancel,
    onTransactionsPopupSubmit
  } = useTransactions();

  const { containerRef, availableWidth } = useAvailableWidth([envVars.editItem, envVars.editTask, transactionsFetching]);

  const simplifyLayout = availableWidth <= SIMPLIFIED_LAYOUT_MAX_WIDTH;

  const [localReasons, setLocalReasons] = useState([]);

  const [prevEditItemId, setPrevEditItemId] = useState(null);

  const selectedTransactionsUsesItems = useMemo(() => {
    return selectedTransactionsData.every(({ usesItems }) => usesItems);
  }, [selectedTransactionsData]);

  const allItemsSelected = !!editableTransactionsIds.length
    && editableTransactionsIds.every((id) => selectedTransactions.includes(id));

  const subTitle = transactionsLastSyncedAt && !businessUser
    ? Utils.replaceTextVars(
      uiTexts.lastSyncDate,
      { date: moment.duration(moment.utc(transactionsLastSyncedAt).diff(moment.utc())).humanize(true) }
    )
    : null;

  const tooMuchData = !!xeroBusinessHasTooMuchData;

  const byMonthData = useMemo(() => {
    return [NEED_REACTION, TO_RECONCILE, TO_REPORT, TO_REVIEW].reduce((result, status) => {
      const sectionData = (globalStats.transactions || {})[status] || {};

      if (sectionData.byMonth) {
        Object.entries(sectionData.byMonth).forEach(([month, { count, comments }]) => {
          const { count: prevCount = 0, comments: prevComments = 0 } = result[month] || {};

          result[month] = { count: count + prevCount, comments: comments + prevComments };
        });
      }

      return result;
    }, {});
  }, [globalStats.transactions]);

  const handleSelectAllChange = useCallback(() => {
    setSelectedTransactions(allItemsSelected ? [] : editableTransactionsIds);
  }, [allItemsSelected, editableTransactionsIds, setSelectedTransactions]);

  const handleReasonBlur = useCallback(() => {
    const reasons = transactionsState.map((item) => item.reason).filter(Boolean);

    setLocalReasons(() => [...new Set(reasons)]);
  }, [transactionsState, setLocalReasons]);

  const handleMonthTabChange = useCallback((params) => {
    if (params.fromDate !== envVars.fromDate || params.toDate !== envVars.toDate) {
      setEnvVars({ ...params, text: null, type: null, accountId: null });
    } else {
      refetchTransactions();
    }
  }, [setEnvVars, envVars.fromDate, envVars.toDate, refetchTransactions]);

  const handleStatusTabClick = useCallback((value) => {
    if (envVars.status === value) refetchTransactions();
  }, [envVars.status, refetchTransactions]);

  useEffect(() => {
    if (editItemId) setEnvVars({ text: editItemId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPrevEditItemId(editItemId);
  }, [editItemId, prevEditItemId, setEnvVars, envVars.text]);

  const providedContext = useMemo(() => {
    return {
      transactionsData,
      transactionsState,
      setTransactionsState,
      refetchTransactions,
      localReasons,
      setLocalReasons
    };
  }, [
    localReasons,
    refetchTransactions,
    setTransactionsState,
    transactionsData,
    transactionsState
  ]);

  useEffect(() => {
    setSelectedTransactions((prevState) => {
      return prevState.filter((id) => Utils.arrayFindById(transactionsData, id));
    });
  }, [setSelectedTransactions, transactionsData]);

  useEffect(() => {
    const reasons = [...new Set(transactionsData.map((itemState) => itemState.reason).filter(Boolean))];

    if (reasons.length) {
      dispatch(TransactionsActions.fetchTransactionsReasonsList(reasons, true));
    }
  }, [transactionsData, dispatch]);

  useEffect(() => {
    dispatch(UiActions.setOpenedCommentsEntityId(null));
  }, [dispatch, envVars.page, envVars.status, envVars.pageSize]);

  const TableComponent = businessUser ? TransactionsTableBusiness : TransactionsTable;

  return (
    <TransactionsContext.Provider value={providedContext}>
      <Page className={Css.transactionsPage} scrollTop={`${envVars.page}.${envVars.pageSize}`}>
        <PageContent ref={containerRef}>
          <PageHeader subtitle={subTitle} className={Css.pageHeader}>
            {!businessUser && <HeaderAccountantContent />}
          </PageHeader>
          <div className={Css.statusFilters}>
            {userData.guestUser
              ? <div className={Css.description}>{messages.accountantAsksUserDescription}</div>
              : (
                <>
                  <MonthsTabs
                    disabled={transactionsFetching}
                    data={byMonthData}
                    value={`${envVars.fromDate}-${envVars.toDate}`}
                    onChange={handleMonthTabChange} />
                  <TransactionsStatusFilter
                    disabled={transactionsFetching}
                    simplifyLayout={simplifyLayout}
                    onClick={handleStatusTabClick} />
                </>
              )}
          </div>
          {!!selectedTransactions.length && (
            <BulkActions
              disabled={transactionsFetching}
              simplifyLayout={simplifyLayout}
              usesItems={selectedTransactionsUsesItems}
              selectedTransactions={selectedTransactions}
              selectedTransactionsData={selectedTransactionsData}
              localReasons={localReasons}
              onReasonBlur={handleReasonBlur}
              onCancel={onBulkActionsCancel}
              onEdit={onBulkActionsEdit} />
          )}
          <Filters
            disabled={fetching || transactionsFetching}
            compact={simplifyLayout}
            className={classNames(Css.filters, !businessUser && tooMuchData && Css.noBottomPadding)} />
          {!businessUser && tooMuchData && (
            <div className={Css.statusDescription}>
              <Icons.Warning className={CommonCss.warningText} />
              <span>{messages.tooMuchData}</span>
            </div>
          )}
          <TableComponent
            disabled={fetching || transactionsFetching}
            simplifyLayout={simplifyLayout}
            localReasons={localReasons}
            allItemsSelected={allItemsSelected}
            editableTransactionsIds={editableTransactionsIds}
            selectedTransactions={selectedTransactions}
            transactionsReadyToReview={transactionsReadyToReview}
            transactionsReadyToProcess={transactionsReadyToProcess}
            transactionsReadyToApprove={transactionsReadyToApprove}
            closedBookDateTransactionIds={closedBookDateTransactionIds}
            availableWidth={availableWidth}
            onReasonBlur={handleReasonBlur}
            onSelectedChange={onSelectedChange}
            onSelectAllChange={handleSelectAllChange} />
          {!transactionsData.length && !!transactionsState && (
            <EmptyState fetchingData={transactionsFetching} />
          )}
          {(!businessOrganization || selectedBusinessHasBusinessUsers) && (
            <AskClientPopup
              disabled={fetching || transactionsFetching}
              statusFilter={envVars.status}
              transactionIds={selectedNeedReactionIds}
              refetchTableData={refetchTransactions}
              onClose={onAskClientPopupClose} />
          )}
          <TransactionsPopup
            disabled={fetching || transactionsFetching}
            popupMode={popupMode}
            transactionsForPopup={transactionsForPopup}
            refetchTableData={refetchTransactions}
            onSubmit={onTransactionsPopupSubmit} />
        </PageContent>
      </Page>
      {!!envVars.editItem && <Sidebar />}
      {!whiteLabel && <TransactionsIntroModal />}
    </TransactionsContext.Provider>
  );
};

export default React.memo(TransactionsPage);
