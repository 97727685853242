import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { TransactionsContext } from "mlib/pages/TransactionsPage";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useSelector } from "react-redux";
import AttachedDocumentActions from "nlib/common/AttachedDocumentActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DocumentPreview from "./lib/DocumentPreview";
import DragAndDropArea from "nlib/ui/DragAndDropArea";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useContext, useState } from "react";
import UserRoles from "const/UserRoles";
import classNames from "classnames";
import useAttachDocument from "hooks/useAttachDocument";

const {
  STATUSES: { TO_REVIEW, TO_REPORT, EXPORTED, EXCLUDED }
} = DataConstants;

const ACCEPT_FILE_TYPES = Object
  .values(Constants.DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions, mimeType }) => [...aggregator, ...extensions, mimeType], []);

const Document = ({ className, transaction, onPreviewClick }) => {
  const {
    id: transactionId,
    type,
    documentId,
    status,
    documentPreview
  } = transaction;

  const { refetchTransactions } = useContext(TransactionsContext);

  const { uiTexts } = useSelector(getTextsData);

  const userRole = useSelector(getUserRole);

  const attachDocument = useAttachDocument({ id: transactionId, documentId, type });

  const [loading, setLoading] = useState(false);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const disabledInput = businessUser ? status === TO_REVIEW : [TO_REPORT, EXCLUDED, EXPORTED].includes(status);

  const handleDropFiles = useCallback(async([file]) => {
    if (!file) return;
    setLoading(true);

    const result = await attachDocument(file);

    if (result) refetchTransactions();

    setLoading(false);
  }, [attachDocument, refetchTransactions]);

  if (documentId) {
    return (
      <div className={classNames(Css.document, className)}>
        <DocumentPreview
          documentPreview={documentPreview}
          onClick={onPreviewClick} />
        <AttachedDocumentActions
          className={Css.buttons}
          transaction={transaction} />
      </div>
    );
  }

  return (
    <div className={classNames(Css.document, className)}>
      <DragAndDropArea
        multiple={false}
        disabled={loading || disabledInput}
        accept={ACCEPT_FILE_TYPES}
        className={Css.dragArea}
        onDrop={handleDropFiles}>
        {({ dragOver, accepted }) => {
          if (loading) {
            return (<Preloader className={Css.preloader} />);
          }

          if (dragOver) {
            return (<Icons.UploadSimple />);
          }

          if (accepted.length) {
            return (
              <div className={Css.extensions}>
                {accepted[0].name.split(".").slice(-1)}
              </div>
            );
          }

          return (
            <div className={Css.placeholder}>
              <Icons.FileArrowUp disabled={disabledInput} />
              {!disabledInput && (
                <div className={Css.text}>{uiTexts.addDocument}</div>
              )}
            </div>
          );
        }}
      </DragAndDropArea>
      {loading && (
        <Preloader absolute className={Css.preloader} />
      )}
    </div>
  );
};

export default React.memo(Document);
