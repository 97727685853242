import Css from "./style.module.scss";

import { checkTransactionsFetching, getTransactionsData } from "selectors/transactions";
import { getSelectedBusinessId } from "selectors/businesses";
import { useSelector } from "react-redux";
import React, { useCallback, useEffect, useRef } from "react";
import SideBarComponent from "nlib/common/SideBar";
import Transaction from "./lib/Transaction";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";

const Sidebar = () => {
  const [{ editItem, text }, setEnvVars] = useEnvVars();

  const [editItemId, section] = editItem ? editItem.split(".") : [];

  const transactionsFetching = useSelector(checkTransactionsFetching);

  const transactionsData = useSelector(getTransactionsData);

  const selectedBussinesId = useSelector(getSelectedBusinessId);

  const componentDidMountRef = useRef(false);

  const transaction = Utils.arrayFindById(transactionsData, editItemId);

  const hasTransaction = !!transaction;

  const commentsSection = section === "comments";

  const handleClose = useCallback(() => {
    setEnvVars({ editItem: null, text: editItemId === text ? null : text });
  }, [text, editItemId, setEnvVars]);

  useEffect(() => {
    if (!componentDidMountRef.current) {
      componentDidMountRef.current = true;

      return;
    }

    if (commentsSection) return;

    if (transactionsFetching) return;

    if (!transaction || !transaction.documentId) {
      setEnvVars({ editItem: null });
    }
  }, [
    transactionsFetching,
    selectedBussinesId,
    commentsSection,
    transaction,
    editItemId,
    text,
    setEnvVars
  ]);

  useEffect(() => {
    if (editItemId && commentsSection) {
      setEnvVars({ editItem: null, text: editItemId });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.body.dataset.documentPreview = "";

    return () => {
      delete document.body.dataset.documentPreview;
    };
  }, []);

  if (commentsSection) return null;

  return (
    <SideBarComponent className={Css.documentPreview}>
      {hasTransaction && (
        <Transaction
          transaction={transaction}
          onClose={handleClose} />
      )}
    </SideBarComponent>
  );
};

export default React.memo(Sidebar);
