import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessHasWhoAsk,
  checkSelectedBusinessRpaMode
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import NoDataContent from "nlib/common/NoDataContent";
import Preloader from "nlib/common/Preloader";
import React from "react";
import TransactionsStatuses from "nlib/pages/TransactionsPage/TransactionsStatuses";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";

const EmptyState = ({ fetchingData }) => {
  const [{ status: statusFilter }] = useEnvVars();

  const { messages, uiTexts } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const { businessOrganization } = useSelector(getActiveOrganization);

  const selectedBusinessHasWhoAsk = useSelector(checkSelectedBusinessHasWhoAsk);

  const { labelLangId: statusLabelLangId } = (statusFilter && TransactionsStatuses.getStatusData([
    statusFilter,
    businessOrganization,
    rpaMode && !selectedBusinessHasWhoAsk,
    businessUser
  ])) || {};

  const statusLabel = statusLabelLangId && uiTexts[statusLabelLangId].toLowerCase();

  return (
    <div className={Css.emptyState}>
      {fetchingData ? <Preloader /> : (
        <NoDataContent
          icon={!statusLabel && businessUser ? Icons.CheckCircle : Icons.CreditCard}
          title={statusLabel
            ? Utils.replaceTextVars(uiTexts.noStatusTransactions, { status: statusLabel })
            : (businessUser ? messages.allThingsDone : uiTexts.noTransactions)} />
      )}
    </div>
  );
};

export default React.memo(EmptyState);
