import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import {
  getCurrentZohoOrganizationId,
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Actions from "./lib/Actions";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import Document from "nlib/common/TransactionsTable/lib/ExtraCell/lib/Document";
import LastComment from "./lib/LastComment";
import React, { useCallback, useMemo } from "react";
import ReasonInput from "nlib/common/ReasonInput";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import SelectClassInput from "nlib/common/SelectClassInput";
import SelectContactInput from "nlib/common/SelectContactInput";
import SelectLocationInput from "nlib/common/SelectLocationInput";
import SelectProjectInput from "nlib/common/SelectProjectInput";
import SelectTaxRateInput from "nlib/common/SelectTaxRateInput";
import Transactions from "utils/Transactions";
import classNames from "classnames";

const {
  TRANSACTION_TYPES: { WITHDRAW },
  STATUSES: { TO_REVIEW },
  ADVANCED_TRANSACTION_TYPES: { TRANSFER, BILL_PAYMENT, RECEIVED_PAYMENT }
} = DataConstants;

const ExtraCell = (props) => {
  const {
    disabled,
    simplifyLayout = false,
    transaction,
    transactionState,
    localReasons,
    refetchTableData,
    readyToReview,
    onChange,
    onReasonBlur
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const { countryCode } = useSelector(getActiveOrganization);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const classes = useSelector(getSelectedBusinessClasses);

  const locations = useSelector(getSelectedBusinessLocations);

  const projects = useSelector(getSelectedBusinessProjects);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const {
    settings: {
      allowClientPayeeSelection,
      allowClientProjectSelection,
      allowClientCategorySelection,
      allowClientTaxRateSelection,
      allowClientClassSelection,
      allowClientLocationSelection
    } = {}
  } = useSelector(getSelectedBusinessData);

  const {
    id: transactionId,
    type,
    reason: originalReason,
    status,
    comments,
    extraData,
    usesItems,
    description,
    lastComment
  } = transaction;

  const {
    vendorId,
    reason,
    address = {},
    category = {},
    taxRate = {},
    class: classValue = {},
    location = {},
    project = {}
  } = transactionState;

  const { id: classId, name: className } = classValue;

  const { id: locationId, name: locationName } = location;

  const { id: projectId, name: projectName } = project;

  const advancedType = useMemo(() => Transactions.getTransactionAdvancedType({ type, extraData }), [type, extraData]);

  const billPayment = advancedType === BILL_PAYMENT;

  const receivePayment = advancedType === RECEIVED_PAYMENT;

  const typeTransfer = advancedType === TRANSFER;

  const withdrawTransaction = type === WITHDRAW;

  const disableInputs = disabled || status === TO_REVIEW;

  const disabledContactInput = typeTransfer || disableInputs;

  const usCountry = countryCode === Countries.US;

  const disabledCategoryInput = billPayment || receivePayment || disableInputs;

  const handleReasonChange = useCallback((value) => {
    onChange(transactionId, { reason: value });
  }, [transactionId, onChange]);

  const handleContactChange = useCallback((value) => {
    onChange(transactionId, { address: value, vendorId: value.id });
  }, [transactionId, onChange]);

  const handleTaxRateChange = useCallback((value) => {
    onChange(transactionId, { taxRate: value });
  }, [transactionId, onChange]);

  const handleClassChange = useCallback((newValue) => {
    onChange(transactionId, { class: newValue });
  }, [transactionId, onChange]);

  const handleLocationChange = useCallback((newValue) => {
    onChange(transactionId, { location: newValue });
  }, [transactionId, onChange]);

  const handleProjectChange = useCallback((newValue) => {
    onChange(transactionId, { project: newValue });
  }, [transactionId, onChange]);

  const handleCategoryChange = useCallback((value) => {
    onChange(transactionId, { category: value, tags: value.code ? [value.name] : [] });
  }, [transactionId, onChange]);

  const handleDocumentAttach = useCallback(() => {
    refetchTableData([], true);
  }, [refetchTableData]);

  return (
    <div className={Css.extraCell}>
      <div className={Css.upper}>
        <div className={Css.description}>
          <div className={Css.content}>
            <div className={classNames(Css.text, !description && Css.muted)} title={description}>
              {description ? <Icons.Info /> : <Icons.Warning className={Css.warning} />}
              <span>
                {description ? description : `${uiTexts.noBankDescriptionOrMemo} :(`}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={Css.fields}>
        <div className={Css.reason}>
          <ReasonInput
            value={reason}
            disabled={disableInputs}
            originalReason={originalReason}
            localReasons={localReasons}
            onBlur={onReasonBlur}
            onChange={handleReasonChange} />
        </div>
        {allowClientPayeeSelection && (
          <div className={Css.extraInput}>
            <SelectContactInput
              useWidget
              useTooltip
              disabled={disabledContactInput}
              value={address}
              onlyVendors={withdrawTransaction && zohoBusiness}
              onlyCustomers={!!usesItems || (!withdrawTransaction && zohoBusiness)}
              probablyVendor={withdrawTransaction && !usesItems}
              valid={!!(address.name && vendorId)}
              active={!!(address.name && !vendorId)}
              onChange={handleContactChange} />
          </div>
        )}
        {!usesItems && allowClientCategorySelection && (
          <div className={Css.extraInput}>
            <SelectCategoryInput
              disabled={disabledCategoryInput}
              entityPaymentType={type}
              value={category}
              valid={!!category.code}
              invalid={!!category.name && !category.code}
              assetAccountsOnly={advancedType === TRANSFER}
              onChange={handleCategoryChange} />
          </div>
        )}
        {allowClientClassSelection && !!classes.length && (
          <div className={Css.extraInput}>
            <SelectClassInput
              useTooltip
              disabled={disableInputs}
              invalid={!!(className && !classId)}
              valid={!!(className && classId)}
              value={classValue}
              onChange={handleClassChange} />
          </div>
        )}
        {allowClientLocationSelection && !!locations.length && (
          <div className={Css.extraInput}>
            <SelectLocationInput
              useTooltip
              disabled={disableInputs}
              invalid={!!(locationName && !locationId)}
              valid={!!(locationName && locationId)}
              value={location}
              onChange={handleLocationChange} />
          </div>
        )}
        {allowClientProjectSelection && !!projects.length && (
          <div className={Css.extraInput}>
            <SelectProjectInput
              useTooltip
              disabled={disableInputs}
              invalid={!!(projectName && !projectId)}
              valid={!!(projectName && projectId)}
              value={project}
              onChange={handleProjectChange} />
          </div>
        )}
        {allowClientTaxRateSelection && !usCountry && !!taxRates.length && (
          <div className={Css.extraInput}>
            <SelectTaxRateInput
              useTooltip
              disabled={disableInputs}
              value={taxRate}
              valid={!!taxRate.id}
              invalid={!!taxRate.name && !taxRate.id}
              onChange={handleTaxRateChange} />
          </div>
        )}
      </div>
      <div className={Css.footer}>
        {!simplifyLayout && (
          <>
            <Document
              vendorId={vendorId}
              advancedType={advancedType}
              transaction={transaction}
              onDocumentAttach={handleDocumentAttach} />
            {lastComment ? (
              <LastComment
                comments={comments}
                lastComment={lastComment}
                transactionId={transactionId} />
            ) : null}
          </>
        )}
        <Actions
          readyToReview={readyToReview}
          transactionState={transactionState}
          transaction={transaction}
          disabled={disabled}
          refetchTableData={refetchTableData} />
      </div>
    </div>
  );
};

export default React.memo(ExtraCell);
