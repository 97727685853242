import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback } from "react";
import useTransactionUtils from "hooks/useTransactionUtils";

const Actions = (props) => {
  const {
    readyToReview,
    transactionState = {},
    transaction,
    disabled,
    refetchTableData
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const { onTransactionApplyChanges } = useTransactionUtils();

  const disableButton = disabled || !readyToReview;

  const handleApplyChangeButtonClick = useCallback(async() => {
    const result = await onTransactionApplyChanges(transaction, transactionState);

    if (result) refetchTableData([transaction.id]);
  }, [transaction, transactionState, refetchTableData, onTransactionApplyChanges]);

  return (
    <div className={Css.actions}>
      <Button
        outline={!disabled && !readyToReview}
        title={uiTexts.send}
        primary={!disableButton}
        disabled={disableButton}
        icon={Icons.PaperPlaneTilt}
        onClick={handleApplyChangeButtonClick}>
        {uiTexts.send}
      </Button>
    </div>
  );
};

export default React.memo(Actions);
