import Css from "./style.module.scss";

import { SideBarContent, SideBarFooter, SideBarHeader } from "nlib/common/SideBar";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AttachedDocumentActions from "nlib/common/AttachedDocumentActions";
import Document from "./lib/Document";
import PdfViewer from "nlib/common/PdfViewer";
import React from "react";
import TransactionInfo from "nlib/common/TransactionInfo";

const TransactionDetails = (props) => {
  const {
    transaction,
    transactionState,
    onClose
  } = props;

  const {
    usesItems,
    documentId,
    documentAttachment
  } = transaction;

  const { uiTexts } = useSelector(getTextsData);

  return (
    <>
      <SideBarHeader onCloseClick={onClose}>{uiTexts.transaction}</SideBarHeader>
      <SideBarContent className={Css.transactionDetails}>
        <TransactionInfo
          documentPreviewMode
          className={Css.transactionInfo}
          transaction={transaction} />
        {!usesItems && (
          (documentAttachment && documentId)
            ? (
              <PdfViewer
                className={Css.pdfViewer}
                attachment={documentAttachment} />
            )
            : (
              <Document
                vendorId={transactionState?.vendorId}
                transaction={transaction}
                className={Css.document} />
            )
        )}
      </SideBarContent>
      <SideBarFooter>
        {!usesItems && documentAttachment && documentId && (
          <AttachedDocumentActions
            className={Css.attachedDocumentActions}
            transaction={transaction} />
        )}
      </SideBarFooter>
    </>
  );
};

export default React.memo(TransactionDetails);
